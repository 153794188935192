import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Crypto from './Components/Crypto';
import Romance from "./Components/Romance";
import  FakeInvestment from "./Components/FakeInvestment";
import ReclaimFunds from './Components/ReclaimFunds';
import Blackmail from "./Components/Blackmail";
import Social from "./Components/Social";
import About from "./Components/About";
import Contact  from "./Components/Contact";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/navbar' element={<Navbar/>}/>
      <Route path='/crypto' element={<Crypto/>}/>
      <Route path='/romance' element={<Romance/>}/>
      <Route path='/fakeInvestment' element={<FakeInvestment/>}/>
      <Route path='/reclaimfunds' element={<ReclaimFunds/>}/>
      <Route path='/blackmail' element={<Blackmail/>}/>
      <Route path='/social' element={<Social/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
    </Routes>
  );
}

export default App;
