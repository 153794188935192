import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { FaCircleNotch } from "react-icons/fa";
import { motion } from 'framer-motion';
import { ImArrowRight } from "react-icons/im";
import { ImArrowDown } from "react-icons/im";
import Loader from "./hooks/Loader";
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";




const Home = () => {
    const [rotation, setRotation] = useState(0);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        setRotation(scrollY * 0.1);
    };

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);


        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const [isOpen, setIsOpen] = useState(false);
    const chatUrl = `mailto:assetretrievalhub@gmail.com`;
    const toggleChat = () => {
        setIsOpen(!isOpen);
      };
    return (
        <>
            <Loader />
           
            <div class="home_image">
           <div className='z-10 relative'>
            <Navbar />
           </div>
                <div className='mx-auto md:max-w-7xl overflow-hidden home_image-content '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-green-0 h-fit'>
                            <div className='flex gap-2 pt-[15%] '>
                                <span className='pt-1'>
                                    <FaCircleNotch color='#258ae0' />
                                </span>
                                <p className='font-bold text-[16px]'>Top professionals  in technology  & recovery </p>
                            </div>

                            <motion.div className='pt-[13%]'
                                initial={{ x: '-100vw' }}
                                animate={{ x: 0 }}
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ type: 'spring', stiffness: 40 }}
                            >
                                <p className='font-[900] xl:text-[50px] text-[35px]'>Experts  In <span className='text-[#258ae0]'>Innovative</span> Technology  & Recovery solution </p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial={{ x: '-100vw' }}
                                animate={{ x: 0 }}
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ type: 'spring', stiffness: 40, delay: 0.3 }}>
                                <p className='text-gray-300 font-semi-bold xl:font-bold'>Explore the forefront of cybersecurity technology. Our elite team of IT specialists delivers cutting-edge solutions to fortify your digital security and elevate your digital presence.</p>
                            </motion.div>

                            <motion.div className='pt-10 mb-[12%]'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.2, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                               <a href="https://assetretrievalhub.com/signup/" target='_blank' rel="noreferrer">
                                <button className='bg-[#258ae0] p-3 font-bold text-white w-[40%] mr-5 get__hover'>
                                    Get Started <i class="ri-arrow-right-s-line"></i>
                                </button>
                               </a>

                                <a href="/contact">
                                    <button className='under_line font-bold'>
                                        Report Scams <i class="ri-arrow-right-s-line"></i>
                                 </button>
                                </a>
                            </motion.div>
                        </div>
                        <div className='h-full'>

                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-5 left-5 z-50">
      {/* Chat Button */}
      <button
        onClick={toggleChat}
        className="bg-gray-200 text-white p-4 rounded-full shadow-lg hover:bg-[#258ae0] transition duration-300 flex items-center justify-center"
      >
        {/* WhatsApp Icon from Remix Icon */}
        <img src="gmail (1).png" alt="" className='w-[30px]'/>
      </button>

      {/* Chat Window */}
      {isOpen && (
        <div className="w-80 h-96 bg-white border border-gray-300 rounded-lg shadow-lg mt-4">
          {/* Header */}
          <div className="bg-[#258ae0] text-white text-center p-3 rounded-t-lg">
            Email Us
          </div>

          {/* Chat Body */}
          <div className="p-3 h-64 overflow-y-scroll bg-gray-50">
            <p className="text-gray-500">
              Send us an email directly ! Click the button below to start chatting.
            </p>
          </div>

          {/* Chat Footer */}
          <div className="p-3 border-t border-gray-300 flex justify-center">
            <a
              href={chatUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#258ae0] text-black px-4 py-2 rounded-lg shadow-lg hover:bg-white transition duration-300"
            >
              Send Us An Email
            </a>
          </div>
        </div>
      )}
    </div>
            <div className='bg-black h-fit '>
                
                <div className='mx-auto md:max-w-7xl overflow-hidden '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#15171e] h-fit p-10'>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='font-bold text-[30px] text-white'>Providing Leading-edge Cybersecurity Solutions</p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='text-gray-300'>Technology serves as the catalyst for advancement and the dissemination of information—essential objectives of information technology. It streamlines processes and addresses a myriad of challenges.</p>
                            </motion.div>
                        </div>
                        <div className='bg-[#258ae0] h-full p-7'>
                            <motion.div className='flex gap-3'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="cloud-database.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Clear and Prompt Resolutions</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-10'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="clock.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Round The clock Stability</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-10'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="like.png"
                                            alt="Avatar"
                                            className="w-[30px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Fast And Assured Recovery</p>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-7xl overflow-hidden mt-10'>
                    <div className='flex gap-2 pt-[8%] mx-3'>
                        <span className='pt-1'>
                            <FaCircleNotch color='#258ae0' />
                        </span>
                        <p className='font-bold text-[16px] text-white uppercase'>Our Core Values</p>
                    </div>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pt-5'>
                            <p className='text-white font-bold text-[25px] xl:text-[33px]'>Reestablishing Financial Balance through Digital Solutions.</p>
                        </div>

                        <div>
                            <p className='text-gray-300 font-semibold'>
                                If you've faced financial setbacks as an investor, trader, miner, shopper, fallen victim to a romance scam, or encountered challenges as an online services user, we are ready to provide assistance and support.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='mx-auto md:max-w-[90rem] mt-10'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <motion.div
                            initial={{ x: '-100vw' }}
                            animate={{ x: 0 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ type: 'spring', stiffness: 60 }}
                        >
                            <img src="https://cdn.mos.cms.futurecdn.net/Qf8GMQiNZHKGz9N9xb6867.jpg" alt="" className="rounded-lg shadow-lg" />
                        </motion.div>

                        <motion.div
                            initial={{ x: '100vw' }}
                            animate={{ x: 0 }}
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ type: 'spring', stiffness: 60 }}
                        >
                            <img src="https://www.pandasecurity.com/en/mediacenter/src/uploads/2022/04/Women-in-Cybersecurity-Hero-Image.jpg" alt="" className="rounded-lg shadow-lg" />
                        </motion.div>
                    </div>
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden mt-10'>
                    <div className='flex gap-2 pt-[8%] mx-3'>
                        <span className='pt-1'>
                            <FaCircleNotch color='#258ae0' />
                        </span>
                        <p className='font-bold text-[16px] text-white uppercase'>Our Top Services</p>
                    </div>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pt-5'>
                            <p className='text-white font-bold text-[25px] xl:text-[33px]'>Innovative Expertise and Cutting-Edge Solutions.</p>
                        </div>

                        <div>
                            <p className='text-gray-300 font-semibold'>
                                Driven by innovation, we continuously challenge the status quo to deliver groundbreaking solutions that redefine industry standards. With a commitment to excellence and a future-focused approach, we empower our clients with the most advanced and impactful strategies to achieve their goals.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='mx-auto md:max-w-[90rem] mt-10'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 pt-10 pb-[10%]'>
                        <motion.div className=' h-fit border__serv p-10 bg-[#258AE0]'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.1, duration: 0.3 }} // Faster transition
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <div>
                                <img src="bitcoin (2).png" alt="" className='w-[60px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-white font-bold text-xl'>Lost Crypto-Currency Recovery</p>
                            </div>
                            <div className='pt-5'>
                                <p className='text-gray-200'>
                                    Lost funds or cryptocurrency? Asset Retrieval is here to help! Our expert team specializes in recovering inaccessible wallets and lost digital assets, using cutting-edge technology and investigative techniques. With dedicated professionalism and a commitment to your financial recovery, we ensure you regain access to your valuable assets swiftly and securely.
                                </p>
                            </div>
                            <a href="/crypto">
                                <div className='pt-10'>
                                    <button className='text-white uppercase'>see more details  <i class="ri-arrow-right-s-line"></i></button>
                                </div>
                            </a>
                        </motion.div>
                        <motion.div className=' h-fit border__serv p-8'

                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.3, duration: 0.3 }} // Faster transition
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <div>
                                <img src="hearts.png" alt="" className='w-[60px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-white font-bold text-xl'>Romance Scam Recovery</p>
                            </div>
                            <div className='pt-5'>
                                <p className='text-gray-200'>
                                    At Asset Retrieval, we specialize in providing compassionate support to victims of romance scams. Trust our dedicated experts to guide you through the recovery process, restoring your confidence and helping you reclaim your lost assets swiftly and effectively. With a personalized approach, we prioritize your emotional well-being while working diligently to ensure you receive the justice and support you deserve.
                                </p>
                            </div>
                           <a href="/romance">
                                <div className='pt-10'>
                                    <button className='text-white uppercase'>see more details  <i class="ri-arrow-right-s-line"></i></button>
                                </div>
                            </a>
                        </motion.div>
                        <motion.div className=' h-full border__serv p-8'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.3 }} // Faster transition
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <div>
                                <img src="profit.png" alt="" className='w-[60px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-white font-bold text-xl'>Fake Investment Recovery</p>
                            </div>
                            <div className='pt-5'>
                                <p className='text-gray-200'>
                                    Struggling to recover from a fake investment? Asset Retrieval is here to help. Our team of dedicated experts swiftly retrieves your lost assets from fraudulent schemes, using cutting-edge technology and thorough investigative techniques. We prioritize your financial security and peace of mind, guiding you every step of the way to ensure a successful recovery.
                                </p>
                            </div>
                           <a href="/fakeInvestment">
                            <div className='pt-10'>
                                <button className='text-white uppercase'>see more details  <i class="ri-arrow-right-s-line"></i></button>
                            </div>
                           </a>
                        </motion.div>
                        <motion.div className=' h-full border__serv p-8'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.3 }} // Faster transition
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <div>
                                <img src="bankruptcy.png" alt="" className='w-[60px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-white font-bold text-xl'>Reclaim Funds from Fake Recovery Agency</p>
                            </div>
                            <div className='pt-5'>
                                <p className='text-gray-200'>
                                    Committed to helping you recover your funds from scammers, our expert team works tirelessly to ensure the swift and secure return of your money. With relentless determination and cutting-edge recovery techniques, we prioritize your financial restoration and peace of mind. You can trust us to be by your side, guiding you through every step of the process and delivering the results you deserve.
                                </p>
                            </div>
                            <a href="/reclaimfunds">
                                <div className='pt-10'>
                                    <button className='text-white uppercase'>see more details  <i class="ri-arrow-right-s-line"></i></button>
                                </div>
                            </a>
                        </motion.div>


                        <motion.div className=' h-full border__serv p-8'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.3 }} // Faster transition
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <div>
                                <img src="blackmail (1).png" alt="" className='w-[60px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-white font-bold text-xl'>Help From Blackmail & Extortion</p>
                            </div>
                            <div className='pt-5'>
                                <p className='text-gray-200'>
                                    Empowering victims of online blackmail, sextortion, and threats, we offer unwavering support and expert guidance throughout the recovery process. Our dedicated team is committed to protecting your privacy and security, using proven strategies to help you regain control of your life. With a compassionate approach and tailored solutions, we stand by your side every step of the way, ensuring you feel empowered and supported during these challenging times.
                                </p>
                            </div>
                            <a href="/blackmail">
                                <div className='pt-10'>
                                    <button className='text-white uppercase'>see more details  <i class="ri-arrow-right-s-line"></i></button>
                                </div>
                            </a>
                        </motion.div>

                        <motion.div className=' h-full border__serv p-8'
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.3 }} // Faster transition
                            variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <div>
                                <img src="social-media-management.png" alt="" className='w-[60px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-white font-bold text-xl'>Social Media Spy & Recovery</p>
                            </div>
                            <div className='pt-5'>
                                <p className='text-gray-200'>
                                    Empowering your digital connections, we specialize in the swift recovery of compromised social accounts. With our expertise and dedication, we ensure you regain access to your accounts promptly and efficiently, minimizing disruption to your online presence. Trust our skilled team to secure your digital identity, providing the support and guidance you need to restore control and protect your personal information from future threats.
                                </p>
                            </div>
                            <a href="/social">
                                <div className='pt-10'>
                                     <button className='text-white uppercase'>see more details  <i class="ri-arrow-right-s-line"></i></button>
                                </div>
                            </a>
                        </motion.div>
                    </div>
                </div>


                <div className='middle__img'>
                    <div className='Middle-text'>
                        <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                            <span className='pt-1'>
                                <FaCircleNotch color='#258ae0' />
                            </span>
                            <p className='font-bold text-[16px] text-white uppercase '>Your Digital Recovery Partner</p>
                        </div>

                        <div className='mx-auto md:max-w-2xl overflow-hidden'>
                            <div className='flex justify-center pt-5'>
                                <p className='font-bold text-[30px] text-center'>
                                    Streamlined Three-Step Asset Retrieval Procedure.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='mx-auto md:max-w-[90rem] mt-10 Middle-text'>
                        <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-4 xl:grid-cols-5 sm:grid-cols-1 md:grid-cols-1 pt-10 pb-[10%]'>
                            <div className='bg-[#15171e] h-fit p-5 border__x'>
                                <div className='flex justify-center'>
                                    <img src="search.png" alt="" className='w-[64px]' />
                                </div>
                                <div className='flex justify-center text-center pt-4'>
                                    <p className='font-bold text-[#258ae0]'>STEP 1</p>
                                </div>
                                <div className='text-center pt-2'>
                                    <p className='font-semibold text-white'>Evaluation and Commencement</p>
                                </div>
                            </div>
                            <div className=' h-fit'>
                                <div className=' justify-center items-center xl:pt-[23%] pt-[15%] hidden md:flex'>
                                    <ImArrowRight size={30} />
                                </div>


                                <div className='justify-center items-center xl:pt-[23%]  pt-[15%] md:hidden flex'>
                                    <ImArrowDown size={30} />
                                </div>
                            </div>
                            <div className='bg-[#15171e] h-fit p-5 border__x'>
                                <div className='flex justify-center'>
                                    <img src="thinking.png" alt="" className='w-[64px]' />
                                </div>
                                <div className='flex justify-center text-center pt-4'>
                                    <p className='font-bold text-[#258ae0]'>STEP 2</p>
                                </div>
                                <div className='text-center pt-2'>
                                    <p className='font-semibold text-white'>Implementation and Recovery</p>
                                </div>
                            </div>
                            <div className=' h-fit'>
                                <div className=' h-fit'>
                                    <div className=' justify-center items-center xl:pt-[23%] pt-[10%] hidden  md:flex'>
                                        <ImArrowRight size={30} />
                                    </div>


                                    <div className='justify-center items-center xl:pt-[23%]  pt-[10%] md:hidden flex'>
                                        <ImArrowDown size={30} />
                                    </div>
                                </div>
                            </div>
                            <div className='bg-[#15171e] h-fit p-5 border__x'>
                                <div className='flex justify-center'>
                                    <img src="setting-lines.png" alt="" className='w-[64px]' />
                                </div>
                                <div className='flex justify-center text-center pt-4'>
                                    <p className='font-bold text-[#258ae0]'>STEP 3</p>
                                </div>
                                <div className='text-center pt-2'>
                                    <p className='font-semibold text-white'>Settlement and Safeguarding</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-[90rem] mt-10 '>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 pt-10 pb-[10%]'>
                        <div className='h-fit'>
                            <div className='flex gap-2 pt-[8%] mx-3'>
                                <span className='pt-1'>
                                    <FaCircleNotch color='#258ae0' />
                                </span>
                                <p className='font-bold text-[16px] text-white uppercase'>
                                    Distinct advantages of Assets Retrieval Hub
                                </p>
                            </div>

                            <div className='pt-5'>
                                <p className='text-white font-bold text-[25px] xl:text-[33px]'>
                                    Four Cornerstones of Exceptional Service.
                                </p>
                            </div>

                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    Selecting Asset Retriverial signifies partnering with experts who are not just versed in the digital recovery domain but also prioritize your tranquility and fiscal solidity. Below are four persuasive arguments that establish us as your optimal selection:
                                </p>
                            </div>

                            <div className='bg-black text-white pt-5 rounded-lg'>
                                <div className='flex items-start'>
                                    <img
                                        src='diamond.png'
                                        alt='Avatar'
                                        className='w-[30px] h-[30px] object-cover'
                                    />

                                    <div className='ml-4'>
                                        <h2 className='text-2xl font-semibold'>
                                            Mastery in Digital Asset Recovery
                                        </h2>
                                        <p className='text-gray-400 mt-2'>
                                            Our team excels beyond mere skill; they are experts in the realm of
                                            digital asset recovery. Possessing in-depth knowledge of blockchain
                                            technology, cybersecurity, and data recovery, we traverse the
                                            intricate digital landscape with expertise and assurance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-black text-white pt-5 rounded-lg'>
                                <div className='flex items-start'>
                                    <img
                                        src='career-path.png'
                                        alt='Avatar'
                                        className='w-[35px] h-[35px] object-cover'
                                    />

                                    <div className='ml-4'>
                                        <h2 className='text-2xl font-semibold'>
                                            Advanced Technological Solutions
                                        </h2>
                                        <p className='text-gray-400 mt-2'>
                                            Our approach integrates the most up-to-date recovery technologies and methodologies, positioning us at the forefront of innovation. Our sophisticated arsenal equips us with a competitive advantage in tracking and recovering your digital assets.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-black text-white pt-5 rounded-lg'>
                                <div className='flex items-start'>
                                    <img
                                        src='approach.png'
                                        alt='Avatar'
                                        className='w-[35px] h-[35px] object-cover'
                                    />

                                    <div className='ml-4'>
                                        <h2 className='text-2xl font-semibold'>
                                            Tailored Recovery Approaches
                                        </h2>
                                        <p className='text-gray-400 mt-2'>
                                            We recognize the distinct nature of every situation, thus we design bespoke recovery strategies. These are carefully tailored to match the particulars of your case, enhancing the likelihood of a successful asset recovery.
                                        </p>
                                    </div>
                                </div>
                            </div>

                           <a href="/about">
                            <button className='under_line font-bold pt-10'>
                                See more about us  <i class="ri-arrow-right-s-line"></i>
                            </button>
                           </a>
                        </div>

                        <div className="flex justify-center overflow-hidden">
                            <img
                                src="https://tech-com.org/wp-content/uploads/2024/07/html-css-collage-concept-with-person-1-2048x1366.jpg"
                                alt=""
                                className="w-[20rem] h-[20rem] md:w-[20rem] md:h-[20rem] lg:w-[30rem] lg:h-[30rem] xl:w-[45rem] xl:h-[45rem] rounded-full object-cover"
                                style={{ transform: `rotate(${rotation}deg)`, transition: 'transform 0.2s ease-in-out' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                    <span className='pt-1'>
                        <FaCircleNotch color='#258ae0' />
                    </span>
                    <p className='font-bold text-[16px] text-white uppercase '>testimonials</p>
                </div>

                <div className='mx-auto md:max-w-4xl overflow-hidden'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                            Genuine Experiences, Practical Solutions – Hear from Our Clients
                        </p>
                    </div>

                    <div className='text-center pt-3'>
                        <p className='text-gray-200'>At  Asset Retriverial, we harness the power of genuine experiences to showcase the impact of our services. Visit our testimonials to discover real client stories of overcoming complex digital challenges with our support.</p>
                    </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>Over an extended period, I discovered myself trapped in the clutches of a fraudulent crypto trading platform. I
                                    entrusted my earned capital, envisioning substantial profits. My savings grew to $100,000, but optimism turned to
                                    disappointment as Asset Retreval Hub gains filled my account. My frustration intensified when attempting a withdrawal validated
                                    my suspicions—withdrawal was an impossible endeavor.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://preview.redd.it/7un2oijtdxy41.jpg?auto=webp&s=99a06a99e27ef7191d141dceef231face043e3d9"
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">John Lockwood</h2>
                                    <p className="text-gray-400">Southhampton</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    In an unexpected turn, I was ensnared by a romantic deception woven by an imposter posing as a military officer.
                                    Convinced to lend $80,000, I awaited a $120,000 return. Regrettably, the scammer disappeared, leaving me financially
                                    devastated and grappling with profound betrayal.
                                    Amid my anguish, fortune favored me when I found  Asset Retrieval Hub, a beacon of hope during my darkest hours. 
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://pbs.twimg.com/media/Fy3l2U4X0AEJePb.jpg:large" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Seraphina Montgomery</h2>
                                    <p className="text-gray-400">Idaho</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    I was in the process of recovering my losses through a supposed recovery firm, but they took a substantial amount of
                                    money from me while promising to retrieve my lost funds. Fortunately, my path crossed with a savior, Asset Retrieval Hub
                                    , who helped me reclaim my money from the fraudulent recovery firm and successfully restored my funds from the
                                    scammers. Their expertise turned the tables on those who had taken advantage of me.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://i.pinimg.com/1200x/1c/e3/9c/1ce39c758dcc18d142743f54771617c2.jpg" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Ryan Mitchell</h2>
                                    <p className="text-gray-400">Baltimore</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    In an unfortunate turn, I mistakenly shared my bank details online, resulting in a swift depletion of over $80,000 from my
                                    account. As the situation worsened with the threat of potential loans, I came across  Asset Retrieval Hub's recommendation. His
                                    expertise proved invaluable - not only did he recover my funds but also brought the scammer to justice, turning the
                                    tide in my favor.  I am immensely grateful for Elias's expertise, which not only rectified the financial damage but
                                    also brought justice to the situation.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://i.pinimg.com/1200x/1c/e3/9c/1ce39c758dcc18d142743f54771617c2.jpg" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Leander Hawthorne</h2>
                                    <p className="text-gray-400">Manitoba</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    Caught in a distressing situation, I found myself blackmailed by someone exploiting my past hardships. This online
                                    extortionist threatened harm to me and my family, leveraging my vulnerabilities. For years, they extracted significant
                                    sums of money from me, leaving me trapped in a cycle of payment. However, a turning point arrived when I was introduced
                                    to  Asset Retrieval Hub.
                                    Asset Retrieval Hub's intervention proved pivotal; he not only put an end to the blackmail but also the  perpetrator faced legal
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://photos.spareroom.com/images/flatshare/listings/large/20/43/204368230.jpg" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Ryan Mitchell</h2>
                                    <p className="text-gray-400">Memphis</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    Ensnared by a romance scammer who exploited my emotions, I found myself sacrificing everything to fulfill his demands.
                                    I sold my properties and even my car, all in an attempt to please him. It was a heart-wrenching realization when I
                                    discovered he was fake.
                                    A friend's recommendation introduced me to  Asset Retrieval Hub, a true savior. With expertise, he unveiled the scammer's
                                    identity, recovered my money, and restored my properties.  Asset Retrieval Hub's actions turned my life around.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://www.shutterstock.com/image-photo/40-years-old-woman-make-260nw-1095142751.jpg" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Percival Beauregard</h2>
                                    <p className="text-gray-400">Southhampton</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                            +1 (617) 360-8778
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>


            <div>
                <Footer/>
            </div>

        </>
    )
}

export default Home