import React from "react";
import { motion } from "framer-motion";

const TransitionEffect = () => {
  return (
    <>
      <motion.div
        className="fixed top-0 bottom-0 right-0 w-1/2 h-screen z-30 bg-[#258AE0]"
        initial={{ x: "0%" }} // Start with the door closed
        animate={{ x: "100%" }} // Animate to x = 100%, sliding to the right (door opening)
        exit={{ x: "0%" }} // Animate back to x = 0%, sliding to the left (door closing)
        transition={{ duration: 0.8, ease: "easeInOut" }}
      />

      <motion.div
        className="fixed top-0 bottom-0 left-0 w-1/2 h-screen z-20 bg-[white]"
        initial={{ x: "0%" }} // Start with the door closed
        animate={{ x: "-100%" }} // Animate to x = -100%, sliding to the left (door opening)
        exit={{ x: "0%" }} // Animate back to x = 0%, sliding to the right (door closing)
        transition={{ delay: 0.8, duration: 0.8, ease: "easeInOut" }}
      />
      <motion.div
        className="fixed top-0 bottom-0 left-0 w-1/2 h-screen z-20 bg-[#0b0c10]"
        initial={{ x: "0%" }} // Start with the door closed
        animate={{ x: "-100%" }} // Animate to x = -100%, sliding to the left (door opening)
        exit={{ x: "0%" }} // Animate back to x = 0%, sliding to the right (door closing)
        transition={{ delay: 0.4, duration: 0.8, ease: "easeInOut" }}
      />

      <motion.div
        className="fixed top-0 bottom-0 right-0 w-1/2 h-screen z-10 bg-[black]"
        initial={{ x: "0%" }} // Start with the door closed
        animate={{ x: "100%" }} // Animate to x = 100%, sliding to the right (door opening)
        exit={{ x: "0%" }} // Animate back to x = 0%, sliding to the left (door closing)
        transition={{ delay: 0.4, duration: 0.8, ease: "easeInOut" }}
      />
    </>
  );
};

export default TransitionEffect;
