import React from 'react'
import Navbar from './Navbar';
import {FaCircleNotch} from "react-icons/fa"
import { motion } from 'framer-motion';
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";
import Loader from "./hooks/Loader"

const Crypto = () => {
    const animationSettings = (delay) => ({
        initial: { opacity: 0, x: -50 }, 
        animate: { opacity: 1, x: 0 },    
        transition: { duration: 0.5, ease: 'easeInOut', delay } 
      });
    return (
        <>
        <Loader/>
            <div className="Invest overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            <div className='flex pt-[20%] gap-1 '>
                                <FaCircleNotch color='#258ae0' className='pt-1' />
                                <div>
                                    <p className='text-white uppercase text-[16px] font-bold'>WE SPECIALIZE IN Fake Investment Recovery.</p>
                                </div>
                            </div>

                            <motion.div className='pt-[25%]'
                             initial={{ x: '-100vw' }}
                             animate={{ x: 0 }}
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ type: 'spring', stiffness: 40 , delay: 1}}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'>Fake Investment Recovery</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bg-black h-fit'>
                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-gren-500 h-fit'>
                            <div className='pt-10'>
                                <p className='font-bold xl:text-[30px] text-[20px] text-white'>Exploring the Intricate Landscape of Your Finance.</p>
                            </div>

                            <div className='pt-5'>
                                <p className='text-gray-300'>
                                Asset Retrieval is a prominent advocate in the fight against fraudulent hackers. With a strong commitment to assisting victims, he has become a leading figure in helping individuals reclaim funds lost to these malicious actors. Our expertise lies in tracing financial transactions, identifying vulnerabilities in cyber systems, and facilitating the recovery process. Driven by a deep sense of justice, we stand firmly against the activities of fake hackers who exploit innocent individuals and organizations. We tirelessly raise awareness about the risks of cybercrime and emphasize the importance of robust cybersecurity measures. Through our efforts, we empower victims with the knowledge and tools needed to reclaim their money and take legal action against the perpetrators.
                                </p> <br />

                                <p className='text-gray-300'>
                                In an age where cyber threats are rampant, Asset Retrieval and his team stand as resolute defenders, working diligently to make the digital landscape safer for everyone. They thwart fake hackers and ensure their ill-gotten gains are returned to their rightful owners. Our mission goes beyond just recovering lost funds; we aim to restore victims' peace of mind and provide them with the support necessary to navigate the aftermath of cyber fraud.
                                </p>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-10 mt-10'>
                            <div className='flex justify-center'>
                                <p className='text-white text-center font-bold'>OUR SERVICES</p>
                            </div>
                            <div className="flex items-center justify-center w-full pt-5">
                                {/* Left dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>

                                {/* Blue square */}
                                <div className="w-4 h-4 bg-blue-500 mx-4"></div>

                                {/* Right dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>
                            </div>
                            <div>
                                <motion.div className='flex pt-5' {...animationSettings(0)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Fake Initial Coin Offerings Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(1)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Boiler Room Scams Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(2)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Bank Investment Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(3)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Help from manipulation
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(4)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Affinity Fraud Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(5)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Broker Investment Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(6)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Offshore Investment Scams Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(7)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                     Binary Options Scams Recovery
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(8)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Fake Hedge Funds or Private Equity Recovery
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(9)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Ponzi Schemes Recovery
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(10)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    24 Hrs Protect
                                    </p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-full mt-10 overflow-hidden '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#258ae0] h-full p-7'>
                            <div className=''> 
                               <p className='font-bold text-[30px] text-white'> Our Compnay Top Core Values and Priority.</p>
                            </div>
                            <motion.div className='flex gap-3 pt-4'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="collaborate.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Integrity</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="empowerment.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Empowerment</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="idea.png"
                                            alt="Avatar"
                                            className="w-[30px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Innovation</p>
                                </div>
                            </motion.div>
                        </div>

                        <div className='bg-[#15171e] h-full p-10'>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='font-bold text-[30px] text-white'>Expert Recovery Services for Fake Investment Scams</p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='text-gray-300'>We offer comprehensive and customized Fake Investment recovery solutions, employing our extensive resources and proven strategies to ensure fast, effective results. Our approach is specifically designed to meet the unique needs and expectations of each client. Whether it's recovering funds from fake investment schemes or other fraudulent activities, we are committed to delivering successful outcomes and restoring financial peace of mind.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>



                <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                    <span className='pt-1'>
                        <FaCircleNotch color='#258ae0' />
                    </span>
                    <p className='font-bold text-[16px] text-white uppercase '>testimonials</p>
                </div>

                <div className='mx-auto md:max-w-4xl overflow-hidden'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                        Genuine Experiences, Proven Solutions – Hear from Our Clients Who Have Struggled with Fake Investments
                        </p>
                    </div>

                    {/* <div className='text-center pt-3'>
                        <p className='text-gray-200'>
                        I was devastated after falling victim to a Ponzi scheme that promised high returns with minimal risk. Thanks to Asset Retrieval, I was able to trace my lost funds and recover a significant portion of my investment. Their expertise and dedication were instrumental in getting my money back. I highly recommend their services to anyone who has been scammed.
                        </p>
                    </div> */}
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I was devastated after falling victim to a Ponzi scheme that promised high returns with minimal risk. Thanks to Asset Retrieval, I was able to trace my lost funds and recover a significant portion of my investment. Their expertise and dedication were instrumental in getting my money back. I highly recommend their services to anyone who has been scammed.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://iran-tc.com/wp-content/uploads/2020/08/%D8%B4%D8%A7%D9%BE%D8%B1%DA%A9.png"
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Robert M.</h2>
                                    <p className="text-gray-400">Ontario</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                After being lured into an advance fee fraud, I thought I had lost all hope. Asset Retrieval stepped in and provided exceptional legal assistance, negotiating with the scammers and helping me reclaim my funds. Their team was professional, supportive, and relentless in their pursuit of justice. I can'
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://media.sciencephoto.com/image/c0309894/800wm" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Emma H</h2>
                                    <p className="text-gray-400">United Kingdom</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Asset Retrieval was a lifesaver when I got caught in a fake ICO scam. They quickly identified the fraudulent activity, coordinated with law enforcement, and managed to recover my lost investment. Their comprehensive forensic financial analysis was key to unraveling the scam. I am incredibly grateful for their swift and effective action.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl8jFLKULZzDOaRgvfejuv_Xjx9NzVF9mpRP_2POYndL4DsWZHAioaTCUTpW7tje9AGrc&usqp=CAU" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Davies</h2>
                                    <p className="text-gray-400">Australia</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Falling prey to a romance scam left me devastated, both emotionally and financially. Then came Asset Retrieval. His meticulous investigation and legal expertise not only uncovered the scam but also restored my lost funds. Asset Retrieval turned my nightmare into a story of triumph. His dedication is unmatched, and I am truly indebted to him.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEkeDRABosM8d3JVVUYRbJMQrr5IJosG70iA&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Nora White</h2>
                                    <p className="text-gray-400">Florida</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I was a victim of a pyramid scheme that preyed on my trust in a close-knit community. Asset Retrieval provided the education and resources I needed to understand the scam and take action. Their team not only helped me recover my funds but also empowered me to protect myself from future scams. Their support was invaluable.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://static3.machteamsoft.ro/thumbnails_med/profile/537/13767537_4546938416crop_1672.jpg" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Kamala K.</h2>
                                    <p className="text-gray-400">
                                    Wyoming
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I was tricked into investing $18,000 in what I thought was a legitimate opportunity, but it turned out to be a well-organized scam. I felt devastated and thought I’d never see that money again. Asset Retrieval stepped in, and their team was incredible! They not only recovered my funds but also guided me through every step of the process. 
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL7Kmri1RB0ZHjMddMGTUAuxMZyeyYwLlvrw&usqp=CAUs" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Johnson</h2>
                                    <p className="text-gray-400">Washington</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                                +1 (617) 360-8778
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div>
                <Footer/>
            </div>
        </>
    )
}

export default Crypto;