import React from 'react';
import { motion } from 'framer-motion'
import Navbar from './Navbar';
import { FaCircleNotch } from "react-icons/fa";
import { Avatar } from '@mui/material';
import Footer from './hooks/Footer';
import Loader from "./hooks/Loader";
const About = () => {
    return (
        <>
        <Loader/>
            <div className="About overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            <div className='flex pt-[20%] gap-1 '>
                                <FaCircleNotch color='#258ae0' className='pt-1' />
                                <div>
                                    <p className='text-white uppercase text-[16px] font-bold'>Pioneering Cyber Solutions</p>
                                </div>
                            </div>

                            <motion.div className='pt-[25%]'
                                initial={{ x: '-100vw' }}
                                animate={{ x: 0 }}
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ type: 'spring', stiffness: 40, delay: 1 }}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'> About Asset <br />Retriverial Hub</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>

            <div className='bg-[#020205] h-fit'>
                <div className='mx-auto md:max-w-7xl overflow-hidden pt-10'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-10 lg:grid-cols-2 xl:grid-cols-2 z-10 relative   sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-een-600 h-fit'>
                            <div>
                                <p className='font-bold xl:text-[25px] text-[20px] text-white'>Reclaiming Financial Security- How Asset Retriverial Help Victims of Fraud and Scam</p>
                            </div>

                            <div>
                                <p className='text-gray-200 pt-5'>At Asset Retriverial, we're dedicated to standing by your side to combat scammers and help restore your hard-earned assets. Combining our specialized expertise with cutting-edge technology, we provide unmatched peace of mind by reclaiming funds lost to fraud. Our team of experienced professionals understands the emotional and financial toll scams can take, and we approach each case with precision and discretion, offering personalized solutions tailored to your needs. Whether you've fallen victim to online phishing, investment fraud, or romance scams, we're ready to navigate even the most complex situations.</p> <br />
                                <div className='mt-10'>
                                    <div className='h-fit line__dotted'>
                                        <div className='flex gap-3 pb-5'>
                                            <img src="layer.png" alt="" className='w-[35px]' />
                                            <div className='pt-2'>
                                                <p className='text-gray-300 font-semibold'>Clear and Prompt Resolution</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='h-fit mt-5 pb-4'>
                                        <div className='flex gap-3 pb-1'>
                                            <img src="user-avatar.png" alt="" className='w-[35px]' />
                                            <div className='pt-2'>
                                                <p className='text-gray-300 font-semibold'>24 Hours Support Line</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='h-fit pt-5 line__dottedx'>
                                        <div className='flex gap-3 pb-5'>
                                            <img src="diamond.png" alt="" className='w-[35px]' />
                                            <div className='pt-1'>
                                                <p className='text-gray-300 font-semibold'>Success-Based Fee Structure</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='h-fit'>
                            <img src="https://jealouscomputers.com/wp-content/uploads/2023/05/Importance-Of-Cyber-Security-1200x900.png" alt="" />
                        </div>
                    </div>
                </div>




                <div className='mx-auto md:max-w-4xl overflow-hidden mt-10'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                           Our Mission & Vision
                        </p>
                    </div>

                    
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden mt-10  '>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-10 lg:grid-cols-2 xl:grid-cols-2 z-10 relative sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#15171e] h-fit p-10'>
                            <div className='flex justify-center pt-10'>
                                <img
                                    src="startup (1).png"
                                    alt="Profile"
                                    className="w-[4rem] h-[4rem] rounded-full bg-[#258ae0] object-cover p-3"
                                />
                            </div>
                            <div className='flex justify-center pt-3'>
                                <p className='text-gray-300 font-bold text-xl'>Mission</p>
                            </div>

                            <p className='text-gray-200 text-center pt-2'>
                                To establish a world where trust and digital safety prevail. We envision a future where individuals can navigate the digital realm with confidence, knowing that they have a steadfast ally in Asset Retriverial. We aspire to set new standards for online security, financial restitution, and victim support, reshaping the landscape of digital trust and protection.
                            </p>
                        </div>
                        <div className='bg-[#15171e] h-full p-10'>
                            <div className='flex justify-center pt-10'>
                                <img
                                    src="opportunity.png"
                                    alt="Profile"
                                    className="w-[4rem] h-[4rem] rounded-full bg-[#258ae0] object-cover p-3"
                                />
                            </div>
                            <div className='flex justify-center pt-3'>
                                <p className='text-gray-300 font-bold text-xl'>Mission</p>
                            </div>

                            <p className='text-gray-200 text-center pt-2'>
                            To relentlessly champion the rights of victims and combat the ever-evolving landscape of financial fraud, online scams, and digital threats. We empower individuals, reclaim stolen assets, restore digital security, and provide unwavering support to those in need. Our mission is to bring financial justice and peace of mind to those affected by deception, with Asset Retriverial leading the charge.
                            </p>
                        </div>
                    </div>
                </div>







                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                                +1 (617) 360-8778
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>

            </div>


        <Footer/>

        </>
    )
}

export default About;