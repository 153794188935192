import React from 'react'
import Navbar from './Navbar';
import {FaCircleNotch} from "react-icons/fa"
import { motion } from 'framer-motion';
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";
import Loader from "./hooks/Loader"

const Crypto = () => {
    const animationSettings = (delay) => ({
        initial: { opacity: 0, x: -50 }, 
        animate: { opacity: 1, x: 0 },    
        transition: { duration: 0.5, ease: 'easeInOut', delay } 
      });
    return (
        <>
        <Loader/>
            <div className="Blackmail overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            <div className='flex pt-[20%] gap-1 '>
                                <FaCircleNotch color='#258ae0' className='pt-1' />
                                <div>
                                    <p className='text-white uppercase text-[16px] font-bold'>WE SPECIALIZE IN HELP FROM BLACKMAIL & EXTORTION</p>
                                </div>
                            </div>

                            <motion.div className='pt-[25%]'
                             initial={{ x: '-100vw' }}
                             animate={{ x: 0 }}
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ type: 'spring', stiffness: 40 , delay: 1}}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'>Help From Blackmail & Extortion</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bg-black h-fit'>
                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-gren-500 h-fit'>
                            <div className='pt-10'>
                                <p className='font-bold xl:text-[30px] text-[20px] text-white'>A Safe Refuge for Victims of Blackmail & Extortion </p>
                            </div>

                            <div className='pt-5'>
                                <p className='text-gray-300'>
                                Asset Retrieval is an advocate for combating the insidious acts of blackmail and extortion. Our mission is to extend a helping hand to individuals who find themselves ensnared in the distressing grip of these malicious practices. We understand the emotional and psychological turmoil that victims often endure when confronted with the threats and manipulation inherent in blackmail and extortion situations. Through our dedicated efforts, we strive to empower and support these individuals on their path to recovery and justice.
                                </p> <br />

                                <p className='text-gray-300'>
                                If you or someone you know is grappling with the harrowing effects of blackmail or extortion, we urge you to take a step towards reclaiming your life. Reach out to us at Asset Retrieval, where our team of experts is committed to providing guidance, resources, and tailored solutions to navigate these challenges. By offering a comprehensive approach, we aim to dismantle the power dynamics wielded by those who perpetrate blackmail and extortion. At Asset Retrieval, we stand resolute in our commitment to fostering a safer environment and a brighter future for all those affected by these harmful actions.
                                </p>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-10 mt-10'>
                            <div className='flex justify-center'>
                                <p className='text-white text-center font-bold'>OUR SERVICES</p>
                            </div>
                            <div className="flex items-center justify-center w-full pt-5">
                                {/* Left dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>

                                {/* Blue square */}
                                <div className="w-4 h-4 bg-blue-500 mx-4"></div>

                                {/* Right dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>
                            </div>
                            <div>
                                <motion.div className='flex pt-5' {...animationSettings(0)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Sextortion Help
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(1)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Help From Online Threats
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(2)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Coercion Help
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(3)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Extortion Help
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(4)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Money Recovery From Blackmail
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(5)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    24Hrs Support
                                    </p>
                                </motion.div>

                                {/* <motion.div className='flex pt-5' {...animationSettings(6)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                     Binary Options Scams Recovery
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(7)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Money Recovery From Fake Cyber Security Experts
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(8)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    24 Hrs Protect
                                    </p>
                                </motion.div> */}
                                
                            </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-full mt-10 overflow-hidden '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#258ae0] h-full p-7'>
                            <div className=''> 
                               <p className='font-bold text-[30px] text-white'> Our Compnay Top Core Values and Priority.</p>
                            </div>
                            <motion.div className='flex gap-3 pt-4'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="collaborate.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Integrity</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="empowerment.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Empowerment</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="idea.png"
                                            alt="Avatar"
                                            className="w-[30px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Innovation</p>
                                </div>
                            </motion.div>
                        </div>

                        <div className='bg-[#15171e] h-full p-10'>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='font-bold text-[30px] text-white'>Asset Retrieval  offers a comprehensive suite of Extortion/Blackmail services.	</p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='text-gray-300'>Asset Retrieval  delivers tailored Extortion/Blackmail Help services, designed to cater to client-specific needs and backed by our advanced resources for prompt, efficient solutions.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>



                <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                    <span className='pt-1'>
                        <FaCircleNotch color='#258ae0' />
                    </span>
                    <p className='font-bold text-[16px] text-white uppercase '>testimonials</p>
                </div>

                <div className='mx-auto md:max-w-4xl overflow-hidden'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                        Genuine Experiences, Proven Solutions – Hear from Our Clients Who Have Struggled with Blackmail & Extortion.
                        </p>
                    </div>

                    {/* <div className='text-center pt-3'>
                        <p className='text-gray-200'>
                        I was devastated after falling victim to a Ponzi scheme that promised high returns with minimal risk. Thanks to Asset Retrieval, I was able to trace my lost funds and recover a significant portion of my investment. Their expertise and dedication were instrumental in getting my money back. I highly recommend their services to anyone who has been scammed.
                        </p>
                    </div> */}
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Asset Retrieval expertise was a lifeline when my family faced a blackmail attempt involving private photos. As 
                                a father of two from Ontario, I needed to safeguard our privacy and end the threat.
                                Asset Retrieval guidance and careful investigation led to swift action, ensuring the safety of my family's privacy. 
                                His legal support proved invaluable in putting an end to the ordeal.
                                I highly recommend Asset Retrieval services to anyone in a similar situation. His dedication and strategic approach
                                are unmatched in dealing with cyber threats and blackmail.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://i.pinimg.com/736x/4e/c4/3d/4ec43d79f28a1d00aff692229aeeecf0.jpg"
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Evangeline Stanton</h2>
                                    <p className="text-gray-400">Mississippi</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                For years, I was in an online relationship with a man named Stephan on Instagram. He promised marriage and, out of love, I 
                                lent him $564,000. He assured me he'd repay, but he abruptly blocked me. Fortunately, I found Asset Retrieval 
                                through a recommendation. With minimal effort, I provided them the scammer's details, and they swiftly recovered my 
                                money. I'm immensely grateful to them.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyn0u1Kcg_VF2lqzsj8ao8XrJqrKG__B8dQg&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Brandi O.</h2>
                                    <p className="text-gray-400">Florida</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                As a young professional from the UK targeted by a cyber blackmailer, Asset Retrieval intervention was my saving grace. 
                                Not only did he expose the fraudster's identity, but he also helped me regain control over my personal and professional 
                                life.
                                Our collaboration showcased the potential of expertise and determination to thwart deceitful schemes. Asset Retrieval
                                guidance empowered me to confront the challenge head-on, ultimately turning the tables on the blackmailer.
                                I'm immensely thankful for Asset Retrieval support in restoring my security and confidence.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://media.istockphoto.com/id/1079932454/photo/selfie-in-bedroom.jpg?s=612x612&w=0&k=20&c=Sqj21EF0ppcBVzkUJ47DAgbp8buiedjCfW3OQCeOXfU=" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Dylan J.</h2>
                                    <p className="text-gray-400">Chicago</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                    I shared my private photos with someone I deeply loved and trusted. But after our relationship ended, he turned against me, using those photos to blackmail me. He threatened to release them online unless I paid him a large sum of money. I was terrified and felt trapped in a nightmare. That’s when I discovered Assets Retrieval. They stepped in with professionalism and care, helping me break free from the toxic grip of my ex. Thanks to their expertise and support, I not only protected my privacy but also regained control of my life. I’m incredibly grateful for the peace of mind they restored.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsg1tZu1j1GN6WZxVJP9dR1L83HcfXD1u4ww&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Jessy H</h2>
                                    <p className="text-gray-400">Florida</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                As an Australian university student confronted with compromising photos being exploited against me, Asset Retrieval 
                                swift action and legal assistance were a lifeline. His intervention empowered me to reclaim control over my narrative, 
                                proving that the images were shared without consent.
                                Asset Retrieval dedication led to a safer online presence for me, allowing me to regain a sense of security. His support
                                throughout the process was invaluable in restoring my peace of mind and asserting my rights.
                                I'm grateful for Asset Retrieval expertise in turning a distressing situation into an opportunity for resolution and empowerment.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://preview.redd.it/t43fymai4xx01.jpg?width=640&crop=smart&auto=webp&s=c65167deacbee7922aeededfa038e5100a387c7c" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Sarah Jessica P.</h2>
                                    <p className="text-gray-400">
                                    Sydney
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Navigating a distressing situation where a blackmailer threatened to expose my personal history was daunting for 
                                me as a single mother from the USA. Asset Retrieval guidance became my beacon of hope, empowering me to protect my 
                                family's reputation and security.
                                With Asset Retrievalt expertise, I transformed fear into triumph and showcased the potential to overcome adversity. 
                                Together, we asserted our rights and turned the tables on the threat.
                                Asset Retrieval support was a pivotal factor in my journey from vulnerability to empowerment. His commitment to 
                                safeguarding my family's dignity and freedom was truly remarkable.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXVlD2LLoN4D2u2NgoMdvTSl5ZGJq86X_n2g&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Jamie V</h2>
                                    <p className="text-gray-400">California</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                                +1 (617) 360-8778
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div>
                <Footer/>
            </div>
        </>
    )
}

export default Crypto;