import React from 'react'
import Navbar from './Navbar';
import {FaCircleNotch} from "react-icons/fa"
import { motion } from 'framer-motion';
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";
import Loader from "./hooks/Loader"

const Crypto = () => {
    const animationSettings = (delay) => ({
        initial: { opacity: 0, x: -50 }, 
        animate: { opacity: 1, x: 0 },    
        transition: { duration: 0.5, ease: 'easeInOut', delay } 
      });
    return (
        <>
        <Loader/>
            <div className="Reclaim overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            <div className='flex pt-[20%] gap-1 '>
                                <FaCircleNotch color='#258ae0' className='pt-1' />
                                <div>
                                    <p className='text-white uppercase text-[16px] font-bold'>WE SPECIALIZE IN Reclaiming Funds from Fake Recovery Agency</p>
                                </div>
                            </div>

                            <motion.div className='pt-[25%]'
                             initial={{ x: '-100vw' }}
                             animate={{ x: 0 }}
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ type: 'spring', stiffness: 40 , delay: 1}}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'>Reclaim Funds from Fake Recovery Agency</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bg-black h-fit'>
                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-gren-500 h-fit'>
                            <div className='pt-10'>
                                <p className='font-bold xl:text-[30px] text-[20px] text-white'>Empowering Victims of Cyber Fraud: Expert Recovery and Cybersecurity Solutions</p>
                            </div>

                            <div className='pt-5'>
                                <p className='text-gray-300'>
                                Asset Retrieval is a prominent advocate in the fight against fraudulent hackers. With a strong commitment to assisting victims, he has become a leading figure in helping individuals reclaim funds lost to these malicious actors. Our expertise lies in tracing financial transactions, identifying vulnerabilities in cyber systems, and facilitating the recovery process. Driven by a deep sense of justice, we stand firmly against the activities of fake hackers who exploit innocent individuals and organizations. We tirelessly raise awareness about the risks of cybercrime and emphasize the importance of robust cybersecurity measures. Through our efforts, we empower victims with the knowledge and tools needed to reclaim their money and take legal action against the perpetrators.
                                </p> <br />

                                <p className='text-gray-300'>
                                In an age where cyber threats are rampant, Asset Retrieval and his team stand as resolute defenders, working diligently to make the digital landscape safer for everyone. They thwart fake hackers and ensure their ill-gotten gains are returned to their rightful owners. Our mission goes beyond just recovering lost funds; we aim to restore victims' peace of mind and provide them with the support necessary to navigate the aftermath of cyber fraud.
                                </p>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-10 mt-10'>
                            <div className='flex justify-center'>
                                <p className='text-white text-center font-bold'>OUR SERVICES</p>
                            </div>
                            <div className="flex items-center justify-center w-full pt-5">
                                {/* Left dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>

                                {/* Blue square */}
                                <div className="w-4 h-4 bg-blue-500 mx-4"></div>

                                {/* Right dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>
                            </div>
                            <div>
                                <motion.div className='flex pt-5' {...animationSettings(0)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Help From False Promises of Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(1)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Help Phishing Scams
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(2)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Help From Impersonation
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(3)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Identification Of Fake Profiles
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(4)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Affinity Fraud Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(5)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Money Recovery From Fake Lawyers
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(6)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                     Binary Options Scams Recovery
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(7)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    Money Recovery From Fake Cyber Security Experts
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(8)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                    24 Hrs Protect
                                    </p>
                                </motion.div>
                                
                            </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-full mt-10 overflow-hidden '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#258ae0] h-full p-7'>
                            <div className=''> 
                               <p className='font-bold text-[30px] text-white'> Our Compnay Top Core Values and Priority.</p>
                            </div>
                            <motion.div className='flex gap-3 pt-4'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="collaborate.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Integrity</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="empowerment.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Empowerment</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="idea.png"
                                            alt="Avatar"
                                            className="w-[30px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Innovation</p>
                                </div>
                            </motion.div>
                        </div>

                        <div className='bg-[#15171e] h-full p-10'>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='font-bold text-[30px] text-white'>Defending Against Cybercrime: Leading the Fight Against Fraudulent Hackers</p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='text-gray-300'>This highlights Asset Retrieval’s proactive stance in combating cybercriminals and fraudulent hackers, emphasizing their role as leaders in the fight against online scams.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>



                <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                    <span className='pt-1'>
                        <FaCircleNotch color='#258ae0' />
                    </span>
                    <p className='font-bold text-[16px] text-white uppercase '>testimonials</p>
                </div>

                <div className='mx-auto md:max-w-4xl overflow-hidden'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                        Genuine Experiences, Proven Solutions – Hear from Our Clients Who Have Struggled with Fake Recovery Agents
                        </p>
                    </div>

                    {/* <div className='text-center pt-3'>
                        <p className='text-gray-200'>
                        I was devastated after falling victim to a Ponzi scheme that promised high returns with minimal risk. Thanks to Asset Retrieval, I was able to trace my lost funds and recover a significant portion of my investment. Their expertise and dedication were instrumental in getting my money back. I highly recommend their services to anyone who has been scammed.
                        </p>
                    </div> */}
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Asset Retrieval  is a true savior! After falling victim to a fake hacker scam, I was devastated and desperate to recover 
                                my funds so i met a fake recovery frim and they took $40,000 from me claiming to restore my money back. Thankfully, 
                                Asset Retrieval  stepped in with his expertise and dedication. He tirelessly pursued the scammers, traced 
                                the transactions, and managed to reclaim my money. His professionalism and commitment to justice are unmatched.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://s3-media1.fl.yelpcdn.com/photo/3_XRHrLZ5gbmYccoxc5BfQ/o.jpg"
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Evangeline Stanton</h2>
                                    <p className="text-gray-400">Mississippi</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Words can't express my gratitude to Asset Retrieval . I was duped by a hacker promising services to restore my disabled
                                instagram  & Facebook account but ended 
                                up losing $18,000. Asset Retrieval t not only recovered my funds but also provided invaluable 
                                guidance on how to enhance my cybersecurity. His knowledge and unwavering support are commendable.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQewlnu3DEn-BGBc0B2zp8Uq4SuqwUPejlsQqiTRefo2nEW0ucFS9R_PW7Z2u5Q1bbdrcw&usqp=CAU" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">finnian mitchell</h2>
                                    <p className="text-gray-400">Louisiana</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I was hesitant to reach out after being scammed by a fake hacker, but Asset Retrieval 's 
                                reputation reassured me. He lived up to every expectation. Not only did he recover my 
                                money efficiently, but he also educated me on the signs of cyber scams. Asset Retrieval  is a beacon 
                                of hope in the fight against fraudulent hackers
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://media.istockphoto.com/id/160409804/photo/portrait-of-an-adult-man.jpg?s=612x612&w=0&k=20&c=HcFtrZkbbu4FU_jl9Id3Uk_vQV5lexKhtR3QUw6QMz8=" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Matteo</h2>
                                    <p className="text-gray-400">Australia</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Asset Retrieval  is a lifesaver! After falling for a fake hacker's trap, After Scamming $10,000 worth of Gift Card from me
                                I thought I'd never see my money again. 
                                But Asset Retrieval t thorough investigation and strategic approach not only led to the recovery of my funds but also 
                                gave me a sense of justice. He's a reliable ally in the battle against cybercrime.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://s.yimg.com/ny/api/res/1.2/xf8iX0vrLG_hRHBiQCMwVA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTgwMA--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2019-11/a1191270-1314-11ea-9fcb-96817ed7091e" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Roslind Mercer</h2>
                                    <p className="text-gray-400">Florida</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Asset Retrieval  is a true guardian of justice in the digital realm. I was left in shambles after 
                                a fake hacker swindled me of $56,000 and he keep on asking for more money. Asset Retrieval  approach was not just about recovering 
                                the funds, but also about restoring my faith. His meticulous investigation and strategic prowess led 
                                to the return of what was rightfully mine. In a world fraught with cyber dangers, Asset Retrieval  stands as a 
                                beacon of hope and expertise.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://static3.machteamsoft.ro/thumbnails_med/profile/537/13767537_4546938416crop_1672.jpg" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Kamala K.</h2>
                                    <p className="text-gray-400">
                                    Wyoming
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Asset Retrieval  is a real savior in the world of digital security. After being scammed out of $76,000 by a fraudulent hacker who relentlessly demanded more, I was completely devastated. But CyberGuardian’s approach wasn’t just about reclaiming the lost money—it was about restoring my trust. Their thorough investigation and strategic expertise ensured that I got back what was rightfully mine. In a landscape filled with online threats, CyberGuardian shines as a symbol of hope and protection.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGFF_LcVNilguAOx_B3zxWk5PypMVYTyqxeg&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Robert K.</h2>
                                    <p className="text-gray-400">Washington</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                            +1 (617) 360-8778 
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div> */}
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div>
                <Footer/>
            </div>
        </>
    )
}

export default Crypto;