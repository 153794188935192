import React from 'react'
import Navbar from './Navbar';
import {FaCircleNotch} from "react-icons/fa"
import { motion } from 'framer-motion';
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";
import Loader from "./hooks/Loader"

const Crypto = () => {
    const animationSettings = (delay) => ({
        initial: { opacity: 0, x: -50 }, 
        animate: { opacity: 1, x: 0 },    
        transition: { duration: 0.5, ease: 'easeInOut', delay } 
      });
    return (
        <>
        <Loader/>
            <div className="Romance overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            <div className='flex pt-[20%] gap-1 '>
                                <FaCircleNotch color='#258ae0' className='pt-1' />
                                <div>
                                    <p className='text-white uppercase text-[16px] font-bold'>WE SPECIALIZE IN ROMANCE SCAM RECOVERY.</p>
                                </div>
                            </div>

                            <motion.div className='pt-[25%]'
                             initial={{ x: '-100vw' }}
                             animate={{ x: 0 }}
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ type: 'spring', stiffness: 40 , delay: 1}}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'>Romance Scam Recovery</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bg-black h-fit'>
                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-gren-500 h-fit'>
                            <div className='pt-10'>
                                <p className='font-bold xl:text-[30px] text-[20px] text-white'>Retrieve Your Finances, Rebuild Your Confidence</p>
                            </div>

                            <div className='pt-10'>
                                <p className='text-gray-300'>
                                At asset retrieval hub, we empathize with the distress caused by Romance and Pig Butchering scams. If you find yourself a victim of these deceptive practices, our dedicated recovery team is here to provide comprehensive assistance in recovering your hard-earned money. Our specialized services encompass a meticulous investigation process where our skilled team traces the origins of the scam and identifies the perpetrators. Building on this foundation, we craft personalized recovery strategies tailored to the unique circumstances of your case.
                                </p> <br />

                                <p className='text-gray-300'>
                                In cases where legal intervention may be necessary, our team collaborates with legal experts to explore viable avenues for legal action against the scammers. Throughout this journey, we prioritize open and transparent communication, ensuring you are kept informed about the progress of your case every step of the way. Remember, you're not navigating this alone. asset retrieval hub is committed to standing by you, offering a helping hand in reclaiming what rightfully belongs to you. Take the proactive step towards financial recovery by reaching out to us and initiating the recovery process.
                                </p>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-10 mt-10'>
                            <div className='flex justify-center'>
                                <p className='text-white text-center font-bold'>OUR SERVICES</p>
                            </div>
                            <div className="flex items-center justify-center w-full pt-5">
                                {/* Left dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>

                                {/* Blue square */}
                                <div className="w-4 h-4 bg-blue-500 mx-4"></div>

                                {/* Right dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>
                            </div>
                            <div>
                                <motion.div className='flex pt-5' {...animationSettings(0)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Loanded Money Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(1)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Military Romance Scam Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(2)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Money Recovery From Romance Scammer
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(3)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Properties Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(4)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Hook Up Scam Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(5)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Broker Investment Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(6)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Broker Investmet Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(7)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Hacked Bank Account Recovery
                                    </p>
                                </motion.div>
                                <motion.div className='flex pt-5' {...animationSettings(8)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        24hrs Protection
                                    </p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-full mt-10 overflow-hidden '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#258ae0] h-full p-7'>
                            <div className=''> 
                               <p className='font-bold text-[30px] text-white'> Our Compnay Top Core Values and Priority.</p>
                            </div>
                            <motion.div className='flex gap-3 pt-4'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="collaborate.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Integrity</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="empowerment.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Empowerment</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="idea.png"
                                            alt="Avatar"
                                            className="w-[30px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Innovation</p>
                                </div>
                            </motion.div>
                        </div>

                        <div className='bg-[#15171e] h-full p-10'>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='font-bold text-[30px] text-white'>Fight Romance Scams with Professional Knowledge					</p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='text-gray-300'>
                                The experts at Asset Retrieval Hub specialize in providing comprehensive guidance on romance scams, leveraging their extensive knowledge to assist victims in navigating these complex situations. They are dedicated to ensuring a thorough and successful recovery of misappropriated funds, offering support every step of the way to help clients regain financial stability and peace of mind.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>



                <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                    <span className='pt-1'>
                        <FaCircleNotch color='#258ae0' />
                    </span>
                    <p className='font-bold text-[16px] text-white uppercase '>testimonials</p>
                </div>

                <div className='mx-auto md:max-w-4xl overflow-hidden'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                        Genuine Experiences, Proven Solutions – Hear from Our Clients That Sufferd from Romance Scams 
                        </p>
                    </div>

                    <div className='text-center pt-3'>
                        <p className='text-gray-200'>
                        At Asset Retrieval, we believe in the transformative power of real stories. Our clients have faced daunting digital challenges in the cryptocurrency world, and their testimonials reflect the genuine impact of our services. Discover how we’ve helped individuals reclaim their cryptocurrency and navigate the complexities of recovery. Visit our testimonials to hear firsthand accounts of triumph and the practical solutions that made it possible.
                        </p>
                    </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                "I never imagined I could be scammed, but I lost $67,000 to someone I thought I was building a future with. Asset Retrieval Hub came to my rescue when I thought all hope was lost. Their expertise and relentless pursuit helped me get every penny back. I'm forever thankful for their amazing support!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf310QzqhQxAHzA6597AwZpP4lRWRfSrcRvA&s"
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Peregrine S.</h2>
                                    <p className="text-gray-400">Portland</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Being scammed out of $130,000 in an online relationship left me feeling helpless and ashamed. But Asset Retrieval Hub turned things around for me. They were efficient, empathetic, and relentless in getting my money back. I don’t know what I would have done without them. They are a true lifesaver!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP4q61fdk69HxQXIRLmKvRvjPeetY2q0Er4g&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Leocadia M</h2>
                                    <p className="text-gray-400">Boulder</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                was manipulated into sending $210,000 to someone who I thought was sincere. When I realized I had been scammed, I was crushed. Asset Retrieval Hub was like a beacon of hope. They worked tirelessly to recover my funds and provided me with the reassurance I desperately needed. 
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAwvGYqw5t1G45htmzh4W0f9nT86q75EPILQ&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Calista W.</h2>
                                    <p className="text-gray-400">Savannah</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                After being scammed out of $71,000, I felt completely shattered. I didn’t know if I’d ever see that money again. Asset Retrieval Hub took immediate action, walking me through every step of the process and successfully recovering my funds. I’m deeply thankful for their professionalism and determination.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOxCkXvkRSfLRn0yvlg3KO8t-UZuB6JrZChA&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Oberon L. </h2>
                                    <p className="text-gray-400">Santa Fe</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I fell victim to a romance scam and lost $45,000 before I realized the truth. I was devastated and didn’t think anyone could help—until I found Asset Retrieval Hub. Their team was incredible, working diligently to recover my funds. I’m forever grateful for their hard work and dedication.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHBt5C5P8t1CFWFO8CoZka38BwyPnQMP8Bwg&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Seraphina J.</h2>
                                    <p className="text-gray-400">Asheville</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I lost $88,500 to a scammer who I thought I could trust. I was embarrassed and devastated. Asset Retrieval Hub gave me hope when I had none. They were patient, thorough, and determined to get my money back, and they did. Their service exceeded my expectations.
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_OOPZ2Ot4O9xcna7QmmJFsbScPLIBpOtBRw&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Thaddeus F.</h2>
                                    <p className="text-gray-400">Flagstaff,</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                                +1 (617) 360-8778 
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div>
                <Footer/>
            </div>
        </>
    )
}

export default Crypto;