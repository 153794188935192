import React from 'react'
import Navbar from './Navbar';
import {FaCircleNotch} from "react-icons/fa"
import { motion } from 'framer-motion';
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";
import Loader from "./hooks/Loader"

const Crypto = () => {
    const animationSettings = (delay) => ({
        initial: { opacity: 0, x: -50 }, 
        animate: { opacity: 1, x: 0 },    
        transition: { duration: 0.5, ease: 'easeInOut', delay } 
      });
    return (
        <>
        <Loader/>
            <div className="crypto overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            <div className='flex pt-[20%] gap-1 '>
                                <FaCircleNotch color='#258ae0' className='pt-1' />
                                <div>
                                    <p className='text-white uppercase text-[16px] font-bold'>We Are experts In Crypto Currency Recovery</p>
                                </div>
                            </div>

                            <motion.div className='pt-[25%]'
                             initial={{ x: '-100vw' }}
                             animate={{ x: 0 }}
                             viewport={{ once: true, amount: 0.5 }}
                             transition={{ type: 'spring', stiffness: 40 , delay: 1}}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'>Lost Cryptocurrency Recovery</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bg-black h-fit'>
                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-gren-500 h-fit'>
                            <div className='pt-10'>
                                <p className='font-bold xl:text-[30px] text-[20px] text-white'>Exploring the Intricate Landscape of Cryptocurrency Frauds</p>
                            </div>

                            <div className='pt-10'>
                                <p className='text-gray-300'>
                                    Bitcoin's meteoric rise as a dominant force in the cryptocurrency world has drawn the attention of both eager investors and unscrupulous scammers. These bad actors often pose as legitimate crypto exchanges, enticing investors with promises of significant returns, only to defraud them of their funds.
                                </p> <br />

                                <p className='text-gray-300'>
                                The Asset Retrieval Hub Cryptocurrency Recovery Program stands as a robust defense against the growing threat of online financial crimes in the digital age. This comprehensive initiative combines cutting-edge intelligence with sophisticated asset-tracing services to provide support for individuals who have fallen victim to cryptocurrency theft. We offer a critical lifeline, connecting victims of cybercrime with a dedicated team of highly skilled professionals, specialized in tracking, tracing, and reclaiming stolen digital assets. Our mission is to empower those affected by these malicious attacks, ensuring that they have the resources and expertise needed to recover their lost investments and navigate the complexities of the cryptocurrency landscape with confidence.
                                </p>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-10 mt-10'>
                            <div className='flex justify-center'>
                                <p className='text-white text-center font-bold'>OUR SERVICES</p>
                            </div>
                            <div className="flex items-center justify-center w-full pt-5">
                                {/* Left dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>

                                {/* Blue square */}
                                <div className="w-4 h-4 bg-blue-500 mx-4"></div>

                                {/* Right dashed line */}
                                <div className="flex-grow border-t border-dashed border-gray-500"></div>
                            </div>
                            <div>
                                <motion.div className='flex pt-5' {...animationSettings(0)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        All Crypto Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(1)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Investment Scam Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(2)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Bank Investment Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(3)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Crypto Investment Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(4)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Hacked Crypto Wallet Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(5)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Fake Broker Investment Recovery
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(6)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        Crypto Wallet Tracing
                                    </p>
                                </motion.div>

                                <motion.div className='flex pt-5' {...animationSettings(7)}>
                                    <i className="ri-arrow-right-s-line text-[#9d9ea0] text-sm font-semibold"></i>
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-[#9d9ea0] to-white text-sm font-semibold uppercase'>
                                        24hrs Protection
                                    </p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='mx-auto md:max-w-full mt-10 overflow-hidden '>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='bg-[#258ae0] h-full p-7'>
                            <div className=''> 
                               <p className='font-bold text-[30px] text-white'> Our Compnay Top Core Values and Priority.</p>
                            </div>
                            <motion.div className='flex gap-3 pt-4'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.3, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="collaborate.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Integrity</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="empowerment.png"
                                            alt="Avatar"
                                            className="w-[35px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Empowerment</p>
                                </div>
                            </motion.div>
                            <motion.div className='flex gap-3 pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <div className="w-[50px] h-[50px] rounded-full overflow-hidden bg-white">
                                    <div className='flex justify-center pt-2'>
                                        <img
                                            src="idea.png"
                                            alt="Avatar"
                                            className="w-[30px] object-cover "
                                        />
                                    </div>
                                </div>
                                <div className='xl:pt-3 pt-2'>
                                    <p className='font-bold text-white'>Innovation</p>
                                </div>
                            </motion.div>
                        </div>

                        <div className='bg-[#15171e] h-full p-10'>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.4, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='font-bold text-[30px] text-white'>We offer comprehensive cryptocurrency recovery services</p>
                            </motion.div>
                            <motion.div className='pt-5'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.6, duration: 0.5 }}
                                variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                }}>
                                <p className='text-gray-300'>We offer comprehensive and tailored cryptocurrency recovery solutions, utilizing our vast resources and proven efficient strategies to deliver time-effective results that are perfectly aligned with our clients' unique needs and expectations.</p>
                            </motion.div>
                        </div>
                    </div>
                </div>



                <div className='flex justify-center gap-2 pt-[5%] mx-3 '>
                    <span className='pt-1'>
                        <FaCircleNotch color='#258ae0' />
                    </span>
                    <p className='font-bold text-[16px] text-white uppercase '>testimonials</p>
                </div>

                <div className='mx-auto md:max-w-4xl overflow-hidden'>
                    <div className='flex justify-center pt-5'>
                        <p className='font-bold text-white text-[30px] text-center'>
                        Genuine Experiences, Proven Solutions – Hear from Our Clients in the Crypto Space
                        </p>
                    </div>

                    <div className='text-center pt-3'>
                        <p className='text-gray-200'>
                        At Asset Retrieval, we believe in the transformative power of real stories. Our clients have faced daunting digital challenges in the cryptocurrency world, and their testimonials reflect the genuine impact of our services. Discover how we’ve helped individuals reclaim their cryptocurrency and navigate the complexities of recovery. Visit our testimonials to hear firsthand accounts of triumph and the practical solutions that made it possible.
                        </p>
                    </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I was scammed out of $78,000 in a fake investment scheme that promised unbelievable returns. Thanks to Asset Retrieval, I not only regained my Bitcoin but also learned valuable lessons about online safety. Their team was professional and supportive throughout the entire process!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://uk.movember.com/uploads/member-profile/dbbf25b6acfd44d840e51e240e1e81ac-6386863d9ed69.jpg"
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">James T.</h2>
                                    <p className="text-gray-400">California</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                When I fell victim to a phishing scam, I lost $85,500 worth of Bitcoin. I felt hopeless until I reached out to Asset Retrieval. They worked diligently to trace my lost funds and successfully recovered my crypto. I can't thank them enough for their expertise and dedication!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYLesVXHXrdGnzsM2gBGUFVVGwy6AmBmUYEw&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Linda K</h2>
                                    <p className="text-gray-400">New York</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                After investing $100,000 in a fraudulent crypto exchange, I thought I’d never see my money again. Asset Retrieval not only recovered my Bitcoin but also provided me with clear guidance on how to avoid similar scams in the future. I'm forever grateful!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZss1BJuR2HMhLaUNTqcyZggyk4rIiDY7hlQ&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Michael R</h2>
                                    <p className="text-gray-400">Texas</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                I was scammed for $50,000 by someone posing as a legitimate trader. I felt ashamed and lost until I found Asset Retrieval. Their team treated me with compassion and professionalism, and they successfully recovered my Bitcoin. I can't recommend them enough!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEkeDRABosM8d3JVVUYRbJMQrr5IJosG70iA&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Samantha J. </h2>
                                    <p className="text-gray-400">Florida</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-fit p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                Investing in cryptocurrency should have been a smart move, but I lost $105,000 to a sophisticated Ponzi scheme. Asset Retrieval restored my faith in the system by helping me recover my funds. Their dedication and knowledge of the crypto space are unmatched!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuDoWFSZ5UUt9yYrU2MYj78Y4kudV-AK25uA&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">David H.</h2>
                                    <p className="text-gray-400">Illinois</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#15171e] h-full p-7'>
                            <div>
                                <img src="quote-left.png" alt="" className='w-[64px]' />
                            </div>
                            <div className='pt-3'>
                                <p className='text-gray-200'>
                                After being deceived by a fake ICO and losing $81,000, I was devastated. Asset Retrieval stepped in and worked tirelessly to recover my lost Bitcoin. Their commitment to their clients is evident, and I can't thank them enough for their help!
                                </p>
                            </div>

                            <div className="text-white rounded-lg flex items-center space-x-4 mt-5">
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp6HzGkGmFZcboGEwr67zek8XNosV0rRVwuA&s" // Replace with your actual image source
                                    alt="Profile"
                                    className="w-[3rem] h-[3rem] rounded-full"
                                />
                                <div>
                                    <h2 className="text-[16px] font-semibold">Emily C</h2>
                                    <p className="text-gray-400">Washington</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='Last_bg mt-[10%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden'>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                                +1 (617) 360-8778
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div>
                <Footer/>
            </div>
        </>
    )
}

export default Crypto;