import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // For hamburger icon

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showServices, setShowServices] = useState(false); // State for showing services

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const toggleServicesDropdown = () => {
        setShowServices(!showServices);
    };

    return (
        <>
            <nav className='!bg-transparent p-5'>
                <div className='mx-auto md:max-w-full overflow-hidden'>
                    <div className='flex justify-between items-center mx-10 xl:mt-0 ml-2 mr-2'>
                        {/* Logo */}
                        <div className='h-fit pt-5'>
                            <img src="Screenshot 2024-10-04 at 4.45.05 PM.png" alt="Logo" className='w-[150px]' />
                        </div>

                        {/* Links for larger screens */}
                        <div className='hidden lg:flex h-fit text-white'>
                            <ul className='flex justify-center items-center gap-10 pt-2'>
                                <li><a href="/" className='font-[16px]'>Home</a></li>
                                <li><a href="/about" className='font-[16px]'>About</a></li>
                                <li>
                                    {/* Services Link with dropdown toggle */}
                                    <div className='font-[16px] cursor-pointer' onClick={toggleServicesDropdown}>
                                        Services <i className="ri-arrow-down-s-line"></i>
                                    </div>

                                    {/* Dropdown box listing services */}
                                    {showServices && (
                                        <div className="absolute bg-gradient-to-r from-[#242426] to-[#343a40] shadow-2xl text-white p-6 mt-4 rounded-xl w-80 border border-[#258AE0] transform transition-transform hover:scale-105 nav_scroll">
                                            <ul className="space-y-4">
                                                <li>
                                                    <a href="/crypto" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                                        Crypto Currency Recovery
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/romance" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                                        Romance Scam Recovery
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/fakeInvestment" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                                        Fake Investment Recovery
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/blackmail" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                                        Help From Blackmail & Extortion
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/social" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                                        Social Media Spy & Recovery
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/reclaimfunds" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                                        Reclaim Funds from Fake Recovery Agency
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>


                                    )}
                                </li>
                                <li><a href="/contact" className='font-[16px]'>Contact</a></li>
                                <li>
                                    <a href="https://assetretrievalhub.com/signup/" target='_blank' rel="noreferrer">
                                        <button className='bg-[#258ae0] p-3 font-bold text-white get__hover w-full'>
                                            Get Started
                                        </button>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        {/* Hamburger icon for mobile */}
                        <div className='lg:hidden'>
                            <button onClick={toggleSidebar} className='text-white'>
                                {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Sidebar for mobile view (from the top) */}
            <div className={`fixed top-0 left-0 w-full bg-gradient-to-r from-[#15171e] to-[#081b2c] z-40 transition-transform transform ${isOpen ? 'translate-y-0' : '-translate-y-full'} shadow-lg`}>
                <ul className='text-white flex flex-col items-start p-6 space-y-6'>
                    <li>
                        <a href="/" className='font-medium text-lg text-gray-300 hover:text-[#258AE0] transition-colors duration-300'>
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="/about" className='font-medium text-lg text-gray-300 hover:text-[#258AE0] transition-colors duration-300'>
                            About
                        </a>
                    </li>
                    <li>
                        {/* Services Link in mobile view */}
                        <div className='font-medium text-lg text-gray-300 cursor-pointer hover:text-[#258AE0] transition-colors duration-300 flex items-center' onClick={toggleServicesDropdown}>
                            Services <i className="ri-arrow-down-s-line ml-2"></i>
                        </div>

                        {/* Dropdown box for services in mobile view */}
                        {showServices && (
                            <div className="mt-4 p-4 bg-gradient-to-r from-[#2c2c2c] to-[#15171e] rounded-md shadow-lg border border-[#258AE0] transition-transform duration-500 ease-in-out transform translate-y-0">
                                <ul className='space-y-3'>
                                    <li>
                                        <a href="/crypto" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                            Crypto Currency Recovery
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/romance" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                            Romance Scam Recovery
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/fakeInvestment" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                            Fake Investment Recovery
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/blackmail" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                            Help From Blackmail & Extortion
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/social" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                            Social Media Spy & Recovery
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/reclaimfunds" className="block py-3 px-4 rounded-md text-lg font-medium bg-[#343a40] hover:bg-[#258AE0] hover:text-black hover:shadow-lg transition-all duration-500 ease-in-out">
                                            Reclaim Funds from Fake Recovery Agency
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </li>
                    <li>
                        <a href="/contact" className='font-medium text-lg text-gray-300 hover:text-[#258AE0] transition-colors duration-300'>
                            Contact
                        </a>
                    </li>
                    <li>
                        <a href="https://assetretrievalhub.com/signup/login" target='_blank' rel="noreferrer" className='font-medium text-lg text-gray-300 hover:text-[#258AE0] transition-colors duration-300'>
                           Login
                        </a>
                    </li>
                    <li>
                        <a href="https://assetretrievalhub.com/signup/" target='_blank' rel="noreferrer">
                            <button className='bg-[#258ae0] p-3 font-bold text-white w-full rounded-md shadow-lg hover:bg-[#1a6fb7] transition-colors duration-300'>
                                Get Started
                            </button>
                        </a>
                    </li>
                </ul>
            </div>


            {/* Backdrop to close sidebar */}
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={toggleSidebar}></div>
            )}
        </>
    );
};

export default Navbar;
