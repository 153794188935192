import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Navbar from "./Navbar";
import Footer from "./hooks/Footer";
import { useForm, ValidationError } from '@formspree/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar } from '@mui/material';
import Loader from "./hooks/Loader";

const Contact = () => {
  const [state, handleSubmit] = useForm("xgvewaky");
  const [formData, setFormData] = useState({
    name: '',
    country: '',
    email: '',
    service: '',
    message: ''
  });

  // Toastify success notification
  const notifySuccess = () => toast.success("Form submitted successfully!", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    style: { backgroundColor: '#22c55e' } // Tailwind green-500
  });

  // Reset form fields
  const resetForm = () => {
    setFormData({
      name: '',
      country: '',
      email: '',
      service: '',
      message: ''
    });
  };

  // Trigger toast and reset form when submission is successful
  useEffect(() => {
    if (state.succeeded) {
      notifySuccess();
      resetForm();
    }
  }, [state.succeeded]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
    <Loader/>
      <div className="About overflow-hidden">
                <div className='z-[99] relative'>
                    <Navbar />
                </div>


                <div className='mx-auto md:max-w-7xl overflow-hidden'>
                    <div className='grid xl:mt-0 mx-3 grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2 z-10 relative  sm:grid-cols-1 md:grid-cols-1 pt-0'>
                        <div className='pb-[15%]'>
                            

                            <motion.div className='pt-[25%]'
                                initial={{ x: '-100vw' }}
                                animate={{ x: 0 }}
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ type: 'spring', stiffness: 40, delay: 1 }}>
                                <p className='font-bold xl:text-[50px] text-[40px] text-white'>Contact Us <br /> Today</p>
                            </motion.div>
                        </div>
                        <div className='een-500 h-fit'>

                        </div>
                    </div>
                </div>

            </div>

     <div className='bg-[#020205] h-fit'>
     <div className='mx-auto md:max-w-7xl overflow-hidden pt-10'>
        <div className='grid mx-2 mt-10 xl:mt-0 my-5  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5'>
          <div className=''>
            <div className='bg-[#020205] py-5'>
              <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-[#15171e] p-6 rounded-lg shadow-md space-y-4">
                <div>
                  <label htmlFor="full-name" className="block  text-gray-200">Full Name</label>
                  <input id="full-name" type="text" name="name" value={formData.name} onChange={handleInputChange} required className="mt-1 border-[#258AE0] block w-full text-white p-2 border rounded-lg shadow-sm focus:ring-[#258AE0] focus:border-[#258AE0] bg-[#020205]" />
                </div>

                <div>
                  <label htmlFor="country" className="block  text-gray-200">Country</label>
                  <input id="country" type="text" name="country" value={formData.country} onChange={handleInputChange} required className="mt-1 border-[#258AE0] block w-full text-white p-2 border rounded-lg shadow-sm focus:ring-[#258AE0] focus:border-[#258AE0] bg-[#020205]" />
                </div>

                <div>
                  <label htmlFor="email" className="block  text-gray-200">Email Address</label>
                  <input id="email" type="email" name="email" value={formData.email} onChange={handleInputChange} required className="mt-1 border-[#258AE0] block w-full text-white p-2 border rounded-lg shadow-sm focus:ring-[#258AE0] focus:border-[#258AE0] bg-[#020205]" />
                  <ValidationError prefix="Email" field="email" errors={state.errors} />
                </div>

                <div>
                  <label htmlFor="services" className="block  text-gray-200">Select Service</label>
                  <select id="services" name="service" value={formData.service} onChange={handleInputChange} required className="mt-1 border-[#258AE0] block w-full text-white p-2 border rounded-lg shadow-sm focus:ring-[#258AE0] focus:border-[#258AE0] bg-[#020205]">
                    <option value="">Select a service</option>
                    <option value="Restore Lost Crypto">Restore Lost Crypto</option>
                        <option value="Romance Scam Recovery">Romance Scam Recovery</option>
                        <option value="Bitcoin Recovery">Bitcoin Recovery</option>
                        <option value="Wallet Tracking">Wallet Tracking</option>
                        <option value="Spy">Spying</option>
                        <option value="Social Media Hack">Social Media hack/Recovery</option>
                        <option value="Icloud Unlock">Icloud Unlock</option>
                        <option value="Account Activation">Account Activation</option>
                        <option value="BlackMail/Extortion">BlackMail/Extortion</option>
                        <option value="USDT Recovery/Hack">USDT Recovery/Hack</option>
                        <option value="Whatsapp Hack/Spy">Whatsapp Hack/Spy</option>
                        <option value="Gmail Recovery/Hack">Gmail Recovery/Hack</option>
                        <option value="Password Recovery">Password Recovery</option>
                        <option value="Background Check Removal">Background Check Removal</option>
                        <option value="Tracking">Tracking</option>
                        <option value="Phoen Unlock">Phone Unlock</option>
                        <option value="Narcissist Victims Support Health Line">Narcissist Victims Support Health Line</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="message" className="block  text-gray-200">Message</label>
                  <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleInputChange} required className="mt-1 border-[#258AE0] block w-full text-white p-2 border rounded-lg shadow-sm focus:ring-[#258AE0] focus:border-[#258AE0] bg-[#020205]"></textarea>
                  <ValidationError prefix="Message" field="message" errors={state.errors} />
                </div>

                <button type="submit" disabled={state.submitting} className="w-full bg-[#258AE0] hover:bg-gray-200 text-black py-2 px-4 rounded-lg shadow-md transition">
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className='mb-10'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53089383.18714171!2d-138.06823843517054!3d35.69377179964879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sng!4v1693352094557!5m2!1sen!2sng"
              width="100%"
              height="100%"
              title="Google Maps"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
     </div>
     <div className='Last_bg pt-[0%]'>
                    <div className='mx-auto max-w-7xl overflow-hidden '>
                        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 relative  z-10 lg:grid-cols-2 xl:grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 pt-10'>
                            <div className='h-fit p-5'>
                                <div>
                                    <p className='font-bold text-[#258AE0] xl:text-[40px] text-[30px] '> Begin Your Digital Asset <span className='text-white'>Recovery Journey </span></p>
                                </div>
                                <div className='pt-5'>
                                    <p className='text-gray-200 mb-10'>
                                    Don't let digital loss be the end of your story. At Asset Retrieval Hub, we're ready to shift the balance in your favor. With our expert recovery solutions, you hold the key to reclaiming what's rightfully yours.
                                    </p>
                                </div>
                            </div>
                            <div className='bg-gree0 h-fit '>
                                <div className='xl:flex gap-5 block'>
                                    {/* <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-whatsapp-line text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white'>
                                                +1 (617) 360-8778
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Talk to An Expert
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className='flex items-start pt-5'>
                                        <Avatar className='!bg-[white]'>
                                            <i class="ri-mail-fill text-[#258AE0]"></i>
                                        </Avatar>

                                        <div className='ml-4'>
                                            <p className=' font-semibold text-white '>
                                            AssetRetrievalHub@gmail.com
                                            </p>
                                            <p className='text-gray-400 uppercase font-bold'>
                                                Email us
                                            </p>
                                        </div>
                                    </div>
                                </div>


                              <a href="/contact">
                                <button className='bg-[#258AE0] w-full mt-10 p-5 down__button'>
                                    <p className='text-white font-bold'>Get Started <i class="ri-arrow-right-s-line"></i></p>
                                </button>
                              </a>
                            </div>
                        </div>  
                    </div>
                </div>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default Contact;
